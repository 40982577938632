<div *ngIf="data.image" class="mb20 image">
  <img src="{{data.image}}" alt="image"/>
</div>

<h2 mat-dialog-title class="title" *ngIf="data.title" [innerHTML]="data.title"></h2>

<div mat-dialog-content class="mb20 content">
  <span *ngIf="data.content" [innerHtml]="data.content | safe: 'html'"></span>
</div>

<div mat-dialog-actions class="actions" align="end">
  <button mat-button color="primary" (click)="close()" tabindex="-1">{{'Accept' | translate}}</button>
</div>