import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatexPipe } from './pipes/datex.pipe';
import { UniqueEmailDirective } from './directives/unique-email.directive';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { PreviewImageComponent } from './components/preview-image/preview-image.component';
import { LoadingComponent } from './components/loading/loading.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { CommonModule, DatePipe } from '@angular/common';
import { MaterialModule } from '../third-party/material/material.module';
import { CookiesModalComponent, CookiesModalContent } from './components/cookies-modal/cookies-modal.component';
import { FileUploadModule } from 'ng2-file-upload';
import { CookiesConfigComponent } from './components/cookies-config/cookies-config.component';
import { RrssComponent } from './components/rrss/rrss.component';
import { ChallengesListComponent } from './components/challenges-list/challenges-list.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { FeaturedPostsComponent } from './components/featured-posts/featured-posts.component';
import { FeaturedELearningsComponent } from './components/featured-e-learnings/featured-e-learnings.component';
import { ProgramSelectionComponent } from './components/program-selection/program-selection.component';
import { CommonHeaderComponent } from './components/common-header/common-header.component';
import { AccessRequiredDialogComponent } from './components/access-required-dialog/access-required-dialog.component';
import { CommercialCommunicationsComponent } from './components/commercial-communications/commercial-communications.component';
import { PopupDialogComponent } from './components/popup-dialog/popup-dialog.component';
import { FontAwesomeModule } from '../third-party/font-awesome/font-awesome.module';
import { ToolbarPublicComponent } from './components/toolbar-public/toolbar-public.component';
import { DatepickerMonthSelectionComponent } from './components/montly-datepicker/montly-datepicker.component';
import { PrivacyDialogComponent } from './components/privacy-dialog/privacy-dialog.component';

@NgModule({
    declarations: [
        DatePickerComponent,
        ConfirmDialogComponent,
        PreviewImageComponent,
        DatexPipe,
        UniqueEmailDirective,
        LoadingComponent,
        InfoDialogComponent,
        CookiesModalComponent,
        CookiesModalContent,
        CookiesConfigComponent,
        RrssComponent,
        ChallengesListComponent,
        SafeHtmlPipe,
        SafePipe,
        FeaturedPostsComponent,
        FeaturedELearningsComponent,
        ProgramSelectionComponent,
        CommonHeaderComponent,
        AccessRequiredDialogComponent,
        CommercialCommunicationsComponent,
        PopupDialogComponent,
        ToolbarPublicComponent,
        DatepickerMonthSelectionComponent,
        PrivacyDialogComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule
    ],
    exports: [
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        ConfirmDialogComponent,
        PreviewImageComponent,
        LoadingComponent,
        InfoDialogComponent,
        DatexPipe,
        SafeHtmlPipe,
        FileUploadModule,
        UniqueEmailDirective,
        CookiesModalComponent,
        CookiesConfigComponent,
        RrssComponent,
        ChallengesListComponent,
        SafePipe,
        FeaturedPostsComponent,
        FeaturedELearningsComponent,
        CommonHeaderComponent,
        AccessRequiredDialogComponent,
        PopupDialogComponent,
        FontAwesomeModule,
        ToolbarPublicComponent,
        DatepickerMonthSelectionComponent,
        PrivacyDialogComponent
    ],
    providers: [
        DatePipe
    ]
})

export class SharedModule { }
