import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PhasesTypesService {

  /**
   * Accumulate points.
   *
   * @memberof PhasesTypesService
   */
  public TYPE_POINTS = 1;

  /**
   * Accumulate sales.
   *
   * @memberof PhasesTypesService
   */
  public TYPE_SALES = 2;

  /**
   * Do tests and see videos.
   *
   * @memberof PhasesTypesService
   */
  public TYPE_TESTS = 3;
}
