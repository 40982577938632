import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.css']
})
export class CommonHeaderComponent implements OnInit {

  /**
   * Title.
   *
   * @type {string}
   * @memberof CommonHeaderComponent
   */
  @Input() title: string;

  /**
   * Subtitle.
   *
   * @type {string}
   * @memberof CommonHeaderComponent
   */
  @Input() subtitle: string;

  constructor(
    public helper: HelperService
  ) { }

  /**
   * On view init.
   *
   * @memberof CommonHeaderComponent
   */
  ngOnInit(): void {}

}
