import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SwUpdate } from '@angular/service-worker';
import { Router, NavigationEnd } from '@angular/router';
import { Location, PopStateEvent } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  /**
   * Last popped url.
   *
   * @private
   * @type {string}
   * @memberof AppComponent
   */
  private lastPoppedUrl: string;

  /**
   * Scroll stack.
   *
   * @private
   * @type {number[]}
   * @memberof AppComponent
   */
  private yScrollStack: number[] = [];

  /**
   * Creates an instance of AppComponent.
   * 
   * @param {TranslateService} translate
   * @memberof AppComponent
   */
  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    private swUpdate: SwUpdate,
    private router: Router,
    private location: Location
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('es');
  }

  /**
   * On view init.
   *
   * @memberof AppComponent
   */
  ngOnInit(): void {
    this.listentoAppUpdates();
    this.scrollRestoration();
  }

  /**
   * Restore scroll on shorter pages when scroll strategy does not reach.
   * 
   *
   * @memberof AppComponent
   */
  scrollRestoration(): void {
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationEnd) {
        let sd = document.getElementsByTagName('mat-sidenav-content');
        if (sd[0]) {
          sd[0].scrollTo(0, 0);
        }
      }
    });
  }

  /**
   * Listen to app updates.
   *
   * @memberof AppComponent
   */
  listentoAppUpdates() {
    /*if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.translate.get('Updating application')
          .subscribe(literal => {

            this.dialog.open(LoadingComponent, {
              disableClose: true,
              data: {
                content: literal
              }
            })
              .afterOpen()
              .subscribe(r => {
                setTimeout(function () {
                  // Reload the current page to load the application again
                  window.location.reload();
                }, 2000);
              })
          });
      });
    }*/
  }
}
