<div class="cookies-content flex flex-center">
    <div class="text flex-spacer">
        Esta web utiliza cookies propias y de terceros para analizar su navegación e introducir mejoras en la navegación
        y ofrecerle un servicio más personalizado. Puede consultar nuestra Política de Cookies <a
            [href]="linksParams.cookiePolicyLink" target="_blank">aquí</a>. Puedes aceptar todas las cookies pulsando el
        botón “Aceptar”, rechazar todas las cookies pulsando el botón “Rechazar” o configurarlas pulsando en el botón
        “ajustes”.
    </div>
    <div>
        <div class="flex flex-center ml10">
            <a mat-button color="primary" href="/cookies-settings">{{'Configure' | translate}}</a>
            <button mat-flat-button color="primary" (click)="acceptAll()">{{'Accept' | translate}}</button>
            <div class="ml20">
                <button mat-button (click)="rejectAll()">
                    {{'Reject' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>