<div class="access-required-dialog-component">

    <div class="container">
        <h3>Si tienes un código promocional, introdúcelo aquí:</h3>

        <div class="flex">
            <div class="form-container">
                <form [formGroup]="form">
                    <mat-form-field class="promo-code" appearance="fill">
                        <mat-label>{{'Access code' | translate}}</mat-label>
                        <input matInput formControlName="access_code" required>
                        <button mat-flat-button class="send-btn" (click)="store()" color="primary" tabindex="-1" matSuffix [disabled]="!form.valid">
                            {{'Accept' | translate}}
                        </button>

                        <mat-error *ngIf="form.controls.access_code.errors">
                            {{'ERROR_REQUIRED_FIELD' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="granted === false">
                        {{'El código no es correcto' | translate}}
                    </mat-error>

                    <mat-error *ngIf="granted === false && allowed === false">
                        {{'No tienes acceso a este reto' | translate}}
                    </mat-error>
                </form>
            </div>
        </div>
    </div>
</div>