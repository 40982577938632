import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { Moment } from 'moment';

const moment = _moment;

export const DATE_FORMAT = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const RETURN_DATE_FORMAT = {
    parse: {
        dateInput: 'YYYY-MM-01',
    }
};

/** @title Datepicker emulating a Year and month picker */
@Component({
    selector: 'app-montly-datepicker',
    templateUrl: './montly-datepicker.component.html',
    styleUrls: ['./montly-datepicker.component.scss'],
    providers: [
        provideMomentDateAdapter(DATE_FORMAT),
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
    ],
    encapsulation: ViewEncapsulation.None
})
export class DatepickerMonthSelectionComponent {
    @Input() title: string;
    @Input() minDate: string = null;
    @Input() maxDate: string = null;
    @Output() dateChangeEvent = new EventEmitter();

    date = new FormControl();

    setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
        const ctrlValue = this.date.value ?? moment();
        ctrlValue.month(normalizedMonthAndYear.month());
        ctrlValue.year(normalizedMonthAndYear.year());
        this.date.setValue(ctrlValue);
        this.dateChangeEvent.emit(ctrlValue.format(RETURN_DATE_FORMAT.parse.dateInput));
        datepicker.close();
    }
}