<div class="program-selection-component">
    <ng-container *ngIf="displayMode == 'program'">
        <h1 mat-dialog-title>
            {{'Program selection title' | translate}}
            <br>
            {{'Program selection subtitle' | translate}}
        </h1>
        <div mat-dialog-content>
            <div class="flex programs-list">
                <mat-card *ngFor="let program of programs" (click)="selectProgram(program)">
                    <div class="current" *ngIf="isCurrent(program)">Seleccionado</div>
                    <img src="/assets/img/brand/logo.png" alt="Logo" />
                    <mat-card-title>{{program.attributes.name}}</mat-card-title>
                </mat-card>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="displayMode == 'policy'">
        <h1 mat-dialog-title>{{'Bases acceptance is required before continue' | translate}}</h1>
        <div class="policy-container">
            <div [innerHTML]="policy"></div>
        </div>
        <div class="flex mt20">
            <div class="flex-spacer">
                <button mat-button (click)="displayMode = 'program'">{{'Cancel' | translate}}</button>
            </div>
            <div>
                <button mat-flat-button color="primary" (click)="acceptBases()">{{'I accept' | translate}}</button>
            </div>
        </div>
    </ng-container>
</div>