import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

/**
 * Program interface
 *
 * @export
 * @interface Program
 */
export interface Program {
  id: number,
  name: string,
  starts_at: string
  ends_at: string,
  status_id: number
}

@Injectable({
  providedIn: 'root'
})
export class ProgramsService {

  private resource: string = 'programs';

  constructor(private http: ApiService) { }

  findAll(filters: Object = {}, sort: string = 'Programs.name', order: string = 'asc', pageNumber: number, pageSize: number = 10) {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=status');
  }

  findOne(id: number) {
    return this.http.get(`/${this.resource}/${id}`);
  }

  getForSelection() {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString({}, 'Programs.name', 'asc', 0, 5000));
  }

  findLocations(id: number) {
    return this.http.get(`/${this.resource}/${id}/locations?sort=name`);
  }

  findUsers(id: number) {
    return this.http.get(`/${this.resource}/${id}/users?sort=name`);
  }

  findActiveProgram() {
    return this.http.get(`/${this.resource}/active?include=phases&fields[programs]=id`);
  }

  findActiveProgramAndActivePhases() {
    return this.http.get(`/${this.resource}/active?include=active_phases_for_user,phases_for_user`);
  }

  getActiveProgramInstructions() {
    return this.http.get(`/${this.resource}/active?fields[programs]=instructions,instructions_no_abassador_no_auth`);
  }

  store(data: Program) {
    if (data.id) {
      return this.http.put(`/${this.resource}/${data.id}`, data);
    } else {
      return this.http.post(`/${this.resource}`, data);
    }
  }

  legalBasesAcceptance(programId: number, userId: number): Observable<any> {
    return this.http.post(`/${this.resource}/${programId}/bases-acceptance`, {
      user_id: userId
    });
  }
}
