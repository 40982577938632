import { Component, OnInit, Input } from "@angular/core";
import { ProgramsService } from "src/app/core/http/programs/programs.service";
import { Router } from "@angular/router";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import * as _moment from "moment";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { StatusService } from "src/app/core/http/status/status.service";
import { PhasesTypesService } from "src/app/core/http/phases-types/phases-types.service";
const moment = _moment;

export const DATE_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-challenges-list",
  templateUrl: "./challenges-list.component.html",
  styleUrls: ["./challenges-list.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class ChallengesListComponent implements OnInit {
  /**
   * Phase type
   *
   * @type {('active' | 'all')}
   * @memberof ChallengesListComponent
   */
  @Input() activePhases: "active" | "all" = "active";

  /**
   * Phase type
   *
   * @type {number}
   * @memberof ChallengesListComponent
   */
  @Input() phaseType: number = this.phasesTypesService.TYPE_POINTS;

  /**
   * Active phases.
   *
   * @type {Array<any>}
   * @memberof ChallengesListComponent
   */
  activeProgram: any = null;

  /**
   * Show or hide the access code field.
   *
   * @type {Array<any>}
   * @memberof ChallengesListComponent
   */
  showCodeField: boolean = false;

  /**
   * List of phases.
   *
   * @type {Array<any>}
   * @memberof ChallengesListComponent
   */
  phasesList: Array<any> = [];

  /**
   * Days to consider a challenge as new.
   *
   * @type {number}
   * @memberof ChallengesListComponent
   */
  daysToSetChallengeAsNew: number = 14;

  /**
   * Creates an instance of ChallengesListComponent.
   *
   * @param {ProgramsService} programsService
   * @param {Router} router
   * @memberof ChallengesListComponent
   */
  constructor(
    private programsService: ProgramsService,
    private statusService: StatusService,
    private phasesTypesService: PhasesTypesService,
    private router: Router
  ) {}

  /**
   * On view init.
   *
   * @memberof ChallengesListComponent
   */
  ngOnInit(): void {
    this.fetchActiveProgram();
  }

  /**
   * Fetch active phases.
   *
   * @memberof ChallengesListComponent
   */
  fetchActiveProgram(): void {
    this.programsService
      .findActiveProgramAndActivePhases()
      .subscribe((program: any) => {
        this.activeProgram = program.data;

        if (
          program.data.include.active_phases_for_user.length > 0 &&
          program.data.include.active_phases_for_user[0].meta.code_required ==
            true &&
          !this.isVisible(program.data.include.active_phases_for_user[0])
        ) {
          this.showCodeField = true;
        }
        let phases = [];
        switch (this.activePhases) {
          case "all":
            phases = program.data.include.phases_for_user;
            break;
          case "active":
          default:
            phases = program.data.include.active_phases_for_user;
            break;
        }

        phases.forEach((element: any) => {
          if (this.phaseType === element.attributes.phase_type_id) {
            this.phasesList.push(element);
          }
        });
      });
  }

  /**
   * Get phase picture background.
   *
   * @param {*} item
   * @returns {(string | null)}
   * @memberof ChallengesListComponent
   */
  getPhasePictureBg(item: any): string | null {
    return item.meta.picture_url ? `url(${item.meta.picture_url})` : null;
  }

  /**
   * Navigate to given challenge
   *
   * @param {*} item
   * @memberof ChallengesListComponent
   */
  navToChallenge(item: any): void {
    this.performChallengeNavigation(item);
  }

  isVisible(item: any): boolean {
    if (item.meta.code_required) {
      let storegeKey: string = `_ac${item.id}`;
      let hasBeenGrantedBefore = localStorage.getItem(storegeKey);

      if (hasBeenGrantedBefore) {
        return true;
      }

      return false;
    }
    return true;
  }

  performChallengeNavigation(item: any): void {
    let route = `/dashboard/challenges/${item.id}`;
    this.router.navigate([route]);
  }

  /**
   * Indicates if the challenge is new.
   *
   * @param {*} item
   * @returns {boolean}
   * @memberof ChallengesListComponent
   */
  isNew(item: any): boolean {
    let now = moment();
    let startsAt = moment(item.attributes.starts_at);

    return now.diff(startsAt, "days") <= this.daysToSetChallengeAsNew;
  }

  /**
   * Indicates if item is active.
   *
   * @param {*} item
   * @returns {boolean}
   * @memberof ChallengesListComponent
   */
  isActive(item: any): boolean {
    return (
      item.attributes.status_id == this.statusService.STATUS_ACTIVE &&
      moment(`${item.attributes.starts_at} 00:00:00`) <= moment() &&
      (moment(`${item.attributes.ends_at} 23:59:59`) >= moment() ||
        moment(`${item.attributes.sales_end_at}`).set({
          hour: 23,
          minute: 59,
        }) >= moment())
    );
  }

  /**
   * Indicates if challenge is completed by user.
   *
   * @param {*} item
   * @returns {boolean}
   * @memberof ChallengesListComponent
   */
  isCompleted(item: any): boolean {
    return item.meta.test_completed_by_user == 1 && item.attributes.phase_type_id === this.phasesTypesService.TYPE_TESTS;
  }

  hasSales(item: any): boolean {
    return item.meta.total_sales_by_user > 0;
  }

  /**
   * Indicates if item is inactive
   *
   * @param {*} item
   * @returns {boolean}
   * @memberof ChallengesListComponent
   */
  isInactive(item: any): boolean {
    return (moment(`${item.attributes.ends_at} 23:59:59`) < moment() || item.attributes.status_id === this.statusService.STATUS_INACTIVE);
  }

  /**
   * Indicates if the challenge is a challenge of type sales.
   *
   * @returns {boolean}
   * @memberof ChallengesListComponent
   */
  isSalesPhase(item: any): boolean {
    return item.attributes.phase_type_id == this.phasesTypesService.TYPE_SALES;
  }

  /**
   * Indicates if the challenge is a challenge of type points.
   *
   * @returns {boolean}
   * @memberof ChallengesListComponent
   */
  isPointsPhase(item: any): boolean {
    return item.attributes.phase_type_id == this.phasesTypesService.TYPE_POINTS;
  }

  /**
   * Indicates if phase is a test based phase.
   *
   * @returns {boolean}
   * @memberof ChallengesListComponent
   */
  isTestPhase(item: any): boolean {
    return item.attributes.phase_type_id == this.phasesTypesService.TYPE_TESTS;
  }
}
