<div class="cookies-config-component">
    <div class="cookies-panel">
        <div class="menu">
            <div class="header">
                <img src="/assets/img/brand/logo.png" alt="logo" />
            </div>

            <div class="sections">
                <button mat-stroked-button tabindex="-1" [color]="partial == 'resume' ? 'primary' : ''"
                    (click)="showPartial('resume')">
                    <mat-icon>verified_user</mat-icon>
                    Resumen de privacidad
                </button>
                <button mat-stroked-button tabindex="-1" [color]="partial == 'strict' ? 'primary' : ''"
                    (click)="showPartial('strict')">
                    <mat-icon>done_all</mat-icon>
                    <span>Cookies estrictamente necesarias</span>
                </button>
                <button mat-stroked-button tabindex="-1" [color]="partial == 'analytical' ? 'primary' : ''"
                    (click)="showPartial('analytical')">
                    <mat-icon>insert_chart_outlined</mat-icon>
                    <span>Cookies de análisis</span>
                </button>
            </div>
        </div>
        <div class="contents">
            <div class="partials">
                <form [formGroup]="cookiesForm">
                    <div class="partial" *ngIf="partial == 'resume'">
                        <h2>Resumen de privacidad</h2>

                        <div class="content">
                            <p>Cuando visita un sitio web, este puede almacenar o recuperar información en su navegador,
                                principalmente en forma de cookies. Esta información puede ser sobre usted, sus
                                preferencias
                                o su dispositivo y se utiliza principalmente para lograr que el sitio funcione como se
                                espera. La información generalmente no lo identifica en forma directa, pero puede
                                brindarle
                                una experiencia web más personalizada. Como respetamos su privacidad, puede optar por
                                excluir
                                algunos tipos
                                de cookies. Puede hacer clic en los diferentes encabezados de categoría para obtener más
                                información y
                                cambiar nuestra configuración predeterminada.</p>
                        </div>
                    </div>

                    <div class="partial" *ngIf="partial == 'strict'">
                        <h2>Cookies estrictamente necesarias</h2>
                        <mat-slide-toggle [disabled]="true" checked color="primary">{{'Activated' | translate}}
                        </mat-slide-toggle>

                        <div class="content">
                            <p>Estas cookies son necesarias para que el sitio web funcione y no se pueden desactivar en
                                nuestros sistemas. Por lo general, solo se configuran en respuesta a sus acciones
                                realizadas al solicitar servicios, como establecer sus preferencias de privacidad,
                                iniciar sesión o completar formularios. Puede configurar su navegador para bloquear o
                                alertar sobre estas cookies, pero algunas áreas del sitio no funcionarán. Estas cookies
                                no almacenan ninguna información de identificación personal.</p>

                            <table class="cookie-def">
                                <tbody>
                                    <tr>
                                        <td class="header" colspan="2">COOKIES DE ANÁLISIS</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Nombre</strong></td>
                                        <td>gdpr_popup</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Dominio</strong></td>
                                        <td>primadermforxperts.com</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Caducidad</strong></td>
                                        <td>3 meses</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Función</strong></td>
                                        <td>Mostrarnos si el usuario ha consentido a la utilización de cookies en el
                                            aviso (banner) de nuestra página y no mostrarlo cada vez que el usuario
                                            acceda de forma sucesiva.</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Tipo</strong></td>
                                        <td>HTTP Cookie</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="cookie-def">
                                <tbody>
                                    <tr>
                                        <td class="header" colspan="2">COOKIES DE ANÁLISIS</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Nombre</strong></td>
                                        <td>elearning_popup</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Dominio</strong></td>
                                        <td>primadermforxperts.com</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Caducidad</strong></td>
                                        <td>3 meses</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Función</strong></td>
                                        <td>Se muestra al usuario un popup informativo tras iniciar sesión informando sobre la sección de e-learning.</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Tipo</strong></td>
                                        <td>HTTP Cookie</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="partial" *ngIf="partial == 'analytical'">
                        <h2>Cookies de análisis</h2>

                        <mat-slide-toggle formControlName="analytical" color="primary">
                            {{getLiteralForControl('analytical') | translate}}</mat-slide-toggle>

                        <div class="content">
                            <p>Estas cookies nos permiten contar las visitas y fuentes de tráfico para poder evaluar el
                                rendimiento de nuestro sitio y mejorarlo. Nos ayudan a saber qué páginas son las más o
                                las menos visitadas, y cómo los visitantes navegan por el sitio. Toda la información que
                                recogen estas cookies es agregada y, por lo tanto, es anónima. Si no permite utilizar
                                estas cookies, no sabremos cuándo visitó nuestro sitio y no podremos evaluar si funcionó
                                correctamente.</p>

                            <table class="cookie-def">
                                <tbody>
                                    <tr>
                                        <td class="header" colspan="2">COOKIES DE ANÁLISIS</td>
                                    </tr>
                                    <tr>
                                        <td style="width: 20%;"><strong>Nombre</strong></td>
                                        <td>_gat</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Dominio</strong></td>
                                        <td>google.com</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Caducidad</strong></td>
                                        <td>Duración de la sesión</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Función</strong></td>
                                        <td>Se usa para limitar el porcentaje de solicitudes.</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Tipo</strong></td>
                                        <td><a style="font-size: 13px;" title="ver más"
                                                href="https://chrome.google.com/webstore/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=es"
                                                target="_blank" rel="noopener">Opt-out</a></td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="cookie-def">
                                <tbody>
                                    <tr>
                                        <td class="header" colspan="2">COOKIES DE ANÁLISIS</td>
                                    </tr>
                                    <tr>
                                        <td style="width: 20%;"><strong>Nombre</strong></td>
                                        <td>_gid</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Dominio</strong></td>
                                        <td>google.com</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Caducidad</strong></td>
                                        <td>1 año</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Función</strong></td>
                                        <td>Distinguir a los usuarios</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Tipo</strong></td>
                                        <td><a style="font-size: 13px;" title="ver más"
                                                href="https://chrome.google.com/webstore/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=es"
                                                target="_blank" rel="noopener">Opt-out</a></td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="cookie-def">
                                <tbody>
                                    <tr>
                                        <td class="header" colspan="2">COOKIES DE ANÁLISIS</td>
                                    </tr>
                                    <tr>
                                        <td style="width: 20%;"><strong>Nombre</strong></td>
                                        <td>_ga</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Dominio</strong></td>
                                        <td>google.com</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Caducidad</strong></td>
                                        <td>2 año</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Función</strong></td>
                                        <td>Distinguir a los usuarios</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Tipo</strong></td>
                                        <td><a style="font-size: 13px;" title="ver más"
                                                href="https://chrome.google.com/webstore/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=es"
                                                target="_blank" rel="noopener">Opt-out</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            </div>
            <div class="save">
                <button mat-flat-button tabindex="-1" (click)="save()" color="primary">Guardar</button>
            </div>
        </div>
    </div>
</div>