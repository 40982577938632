<mat-icon class="close" mat-button (click)="decline()">close</mat-icon>
<div mat-dialog-content class="mb20 content">

    <div class="flex">
        <h3>{{commercialCommunicationsTitle}}</h3>
    </div>
</div>

<div mat-dialog-actions class="actions">
    <form [formGroup]="form">
        <div class="check">
            <mat-checkbox color="primary" class="mr10 mt15" formControlName="commercial_communications" required>
            </mat-checkbox>
            <p>{{commercialCommunicationsText}}</p>
        </div>
        <div class="continue">
            <button mat-flat-button color="primary" (click)="accept()" tabindex="-1">{{'Continue' | translate}}</button>
        </div>
    </form>
</div>