import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { storageConfig } from 'src/app/config/storage.config';
import { BehaviorSubject } from 'rxjs';
import { UserInterface, UserResource } from '../interfaces/users.resource';

export class UserResourceModel implements UserResource {
  data: UserInterface;
  constructor(data: any) {
    this.data = data;
  }
}
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private _settingsUsersSource = new BehaviorSubject<UserResource>(null);
  settingsUser$ = this._settingsUsersSource.asObservable();

  /**
   *Creates an instance of ApiService.
   * @param {HttpClient} http
   * @memberof ApiService
   */
  constructor(
    private http: HttpClient
  ) { }


  /**
   * Get headers for auth request
   *
   * @returns
   * @memberof ApiService
   */
  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAccessToken()
      })
    };
  }

  /**
   * Get access token.
   *
   * @returns {string}
   * @memberof ApiService
   */
  getAccessToken(): string {
    let session = JSON.parse(localStorage.getItem(storageConfig.authKey));
    if (session && session.access_token) {
      return session.access_token;
    }

    return '';
  }

  /**
   * Get user id.
   *
   * @returns {string}
   * @memberof ApiService
   */
  getUserId(): number {
    let session = JSON.parse(localStorage.getItem(storageConfig.authKey));
    if (session && session.user_id) {
      return session.user_id;
    }

    return;
  }

  /**
   * Get the current program id.
   */
  getProgramId(): string {
    return localStorage.getItem('_cpid');
  }

  /**
   * Set user in local storage.
   *
   * @param {*} userObject
   * @param {string} context
   * @memberof ApiService
   */
  setUser(userObject: any) {
    this._settingsUsersSource.next(userObject);
    localStorage.setItem(storageConfig.userKey, JSON.stringify(userObject));
  }


  /**
   * Get user from local storage.
   *
   * @param {string} context
   * @returns
   * @memberof ApiService
   */
  getUser(): UserResource {
    let user = localStorage.getItem(storageConfig.userKey);
    if (user) {
      user = JSON.parse(user);
      const userModel = new UserResourceModel(user['data']);
      this._settingsUsersSource.next(userModel);
      return userModel;
    }

    return new UserResourceModel({});
  }

  parseParams(params: {} = {}) {
    let p = new HttpParams();

    if (params) {

      for (let key in params) {
        if (typeof params[key] == 'object') {
          for (let skey in params[key]) {
            p = p.set(`${key}[${skey}]`, params[key][skey]);
          }
        } else {
          p = p.set(key, params[key]);
        }
      }

      return '?' + p.toString();
    }

    return '';
  }

  buildListQueryString(filters: Object = {}, sort: string, order: string, pageNumber: number, pageSize: number): string {
    return this.parseParams({
      sort: (order == 'desc' ? '-' : '') + sort,
      page: {
        number: pageNumber + 1,
        size: pageSize
      },
      filter: filters
    });
  }


  /**
   * Perform an authenticated request via GET.
   *
   * @param {string} uri
   * @param {string} [qs='']
   * @returns
   * @memberof ApiService
   */
  get(uri: string, qs: string = '') {
    return this.http.get(environment.apiUrl + uri + qs, this.getHttpOptions());
  }


  /**
   * Perform an authenticated request via POST.
   *
   * @param {string} uri
   * @param {*} body
   * @returns
   * @memberof ApiService
   */
  post(uri: string, body) {
    return this.http.post(environment.apiUrl + uri, body, this.getHttpOptions());
  }


  /**
   * Perform an authenticated request via PUT.
   *
   * @param {string} uri
   * @param {*} body
   * @returns
   * @memberof ApiService
   */
  put(uri: string, body) {
    return this.http.put(environment.apiUrl + uri, body, this.getHttpOptions());
  }


  /**
   * Perform an authenticated request via PUT.
   *
   * @param {string} uri
   * @param {*} body
   * @returns
   * @memberof ApiService
   */
  delete(uri: string) {
    return this.http.delete(environment.apiUrl + uri, this.getHttpOptions());
  }
}
