import { Injectable, ChangeDetectorRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { LoadingComponent } from '../components/loading/loading.component';
import { InfoDialogComponent } from '../components/info-dialog/info-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  /**
   * Observable string sources.
   *
   * @private
   * @memberof HelperService
   */
  private emitChangeSource = new Subject<any>();

  /**
   * Observable string streams
   *
   * @memberof HelperService
   */
  changeEmitted$ = this.emitChangeSource.asObservable();

  /**
   * Service message commands
   *
   * @param {*} change
   * @memberof HelperService
   */
  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }

  /**
   * Mobile query.
   *
   * @type {MediaQueryList}
   * @memberof HelperService
   */
  mobileQuery: MediaQueryList;

  /**
   * Listener for mobileQuery.
   *
   * @private
   * @memberof HelperService
   */
  private _mobileQueryListener: () => void;

  /**
   * Creates an instance of HelperService.
   * 
   * @param {ChangeDetectorRef} changeDetectorRef
   * @param {MediaMatcher} media
   * @param {TranslateService} translate
   * @param {MatDialog} dialog
   * @param {MatSnackBar} snackBar
   * @memberof HelperService
   */
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private translate: TranslateService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  /**
   * Destroy listeners.
   *
   * @memberof HelperService
   */
  destroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  /**
   * Open loading dialog.
   *
   * @param {string} [text='Loading']
   * @memberof HelperService
   */
  openLoading(text: string = 'Loading'): void {
    this.translate.get([
      text
    ]).subscribe((res: string) => {
      this.dialog.open(LoadingComponent, {
        id: 'loading',
        disableClose: true,
        data: {
          content: res[text]
        }
      });
    });
  }

  /**
   * Close loading dialog.
   *
   * @memberof HelperService
   */
  closeLoading(): void {
    this.dialog.getDialogById('loading').close();
  }

  /**
   * Display the error saving dialog.
   *
   * @memberof HelperService
   */
  errorSaving(): void {
    this.dialog.closeAll();
    let text = 'ERROR_SAVING';
    this.translate.get([
      text
    ]).subscribe((res: string) => {
      this.dialog.open(InfoDialogComponent, {
        id: 'error-saving',
        disableClose: true,
        data: {
          content: res[text]
        }
      });
    });
  }

  /**
   * Show a snackbar with the given text.
   *
   * @param {string} [text='Saved successfully']
   * @memberof HelperService
   */
  showSnackBar(text: string = 'Saved successfully'): void {
    this.translate.get([
      text
    ]).subscribe((res: string) => {
      this.snackBar.open(res[text], 'Ok', {
        duration: 2000
      });
    });
  }

  /**
   * Scroll to top.
   *
   * @memberof HelperService
   */
  scrollToTop(): void {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
}
