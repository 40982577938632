import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  private resource: string = 'status';

  public STATUS_ACTIVE: number = 1;
  public STATUS_INACTIVE: number = 2;
  public STATUS_PENDING: number = 3;
  public STATUS_ACCEPTED: number = 4;
  public STATUS_REJECTED: number = 5;
  public STATUS_PROCESSING: number = 7;
  public STATUS_DELIVERED: number = 10;
  public STATUS_DRAFT: number = 11;
  public STATUS_SCHEDULED: number = 12;
  public STATUS_SENDING: number = 13;
  public STATUS_SENT: number = 14;
  public STATUS_CANCELLED: number = 15;
  public STATUS_NEW: number = 16;
  public STATUS_UNREAD = 17;
  public STATUS_READ = 18;
  public STATUS_INCIDENCE = 19;
  public STATUS_DELEGATE = 20;
  
  /**
   * Creates an instance of RolesService.
   * @param {AuthorizationService} http
   * @memberof RolesService
   */
  constructor(private http: ApiService) { }

  /**
   * List items
   *
   * @returns
   * @memberof RolesService
   */
  findAll(filters: Object = {}, sort: string = 'name', order: string = 'asc', pageNumber: number, pageSize: number = 1000) {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }


  /**
   * Get status for users
   *
   * @returns
   * @memberof StatusService
   */
  getForUsers() {
    return this.http.get(`/${this.resource}`, '?filter[id][]=1&filter[id][]=2&filter[id][]=3');
  }

  getActiveInactive() {
    return [
      {
        id: this.STATUS_ACTIVE,
        name: "STATUS_ACTIVE"
      },
      {
        id: this.STATUS_INACTIVE,
        name: "STATUS_INACTIVE"
      }
    ];
  }

  getForSales() {
    return [
      {
        id: this.STATUS_PENDING,
        name: "STATUS_PENDING"
      },
      {
        id: this.STATUS_ACCEPTED,
        name: "STATUS_ACCEPTED"
      },
      {
        id: this.STATUS_REJECTED,
        name: "STATUS_REJECTED"
      },
      {
        id: this.STATUS_INCIDENCE,
        name: "STATUS_INCIDENCE"
      }
    ];
  }

  /**
   * Find a single item
   *
   * @param {number} id
   * @returns
   * @memberof RolesService
   */
  findOne(id: number) {
    return this.http.get(`/${this.resource}/` + id);
  }
}
