import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  /**
   * Api resource
   *
   * @private
   * @type {string}
   * @memberof PostsService
   */
  private resource: string = 'posts';

  /**
   * Creates an instance of PostsService.
   * 
   * @param {AuthorizationService} http
   * @memberof PostsService
   */
  constructor(
    private http: ApiService
  ) { }

  /**
   * List items.
   *
   * @returns
   * @memberof PostsService
   */
  findAll(filters = {}, sort: string = 'BlogPosts.name', order: string = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=categories,pictures');
  }

  /**
   * Find all featured posts
   *
   * @returns {Observable<any>}
   * @memberof PostsService
   */
  findAllFeatured(): Observable<any> {
    return this.findAll({
      'BlogPosts.featured': 1,
      'BlogPosts.status_id': 1,
    }, 'BlogPosts.created_at', 'desc', 0, 1000);
  }

  /**
   * Find all published posts.
   *
   * @returns {Observable<any>}
   * @memberof PostsService
   */
  findAllPublished(): Observable<any> {
    return this.findAll({
      'BlogPosts.status_id': 1
    }, 'BlogPosts.created_at', 'desc', 0, 1000);
  }

  /**
   * Find one post.
   *
   * @param {(string | number)} postId
   * @returns {Observable<any>}
   * @memberof PostsService
   */
  finOne(postId: string | number, params: {}): Observable<any> {
    return this.http.get(`/${this.resource}/${postId}`, this.http.parseParams(params));
  }

  /**
   * Register view.
   *
   * @param {*} postId
   * @param {*} body
   * @returns {Observable<any>}
   * @memberof PostsService
   */
  registerView(body): Observable<any> {
    return this.http.post(`/${this.resource}/view`, body);
  }

}
