<ng-container *ngIf="featuredContents.length > 0">
    <div class="featured-contents-component">
        <div class="content-box transparent">
            <div class="title">{{title | translate}}</div>

            <div class="page-inner">
                <!--<swiper [config]="config">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" *ngFor="let item of featuredContents; let i = index;">
                            <mat-card [matTooltip]="item.attributes.title" (click)="navToContent(item)">
                                <div mat-card-image [ngStyle]="{'background-image': getItemPicture(item)}"></div>
                                <mat-card-content>
                                    <div class="tags">
                                        <div>{{item.meta.type_category_name}}</div>
                                        <div>{{item.meta.speciality_category_name}}</div>
                                    </div>
                                    <h3>{{item.attributes.title}}</h3>
                                    <p *ngIf="item.meta.summary">{{item.meta.summary}}...</p>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </swiper>
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>-->
            </div>
        </div>
    </div>
</ng-container>