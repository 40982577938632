import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/http/api.service';
import { ProgramsService } from 'src/app/core/http/programs/programs.service';
import { UsersService } from 'src/app/core/http/users/users.service';

@Component({
  selector: 'app-program-selection',
  templateUrl: './program-selection.component.html',
  styleUrls: ['./program-selection.component.scss']
})
export class ProgramSelectionComponent implements OnInit {

  /**
   * Available programs.
   */
  programs: Array<any> = [];

  /**
   * Display mode.
   */
  displayMode: 'program' | 'policy' = 'program';

  policy: any = null;

  selectedProgram: any;

  constructor(
    public dialogRef: MatDialogRef<ProgramSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public userId: number,
    private userService: UsersService,
    private apiService: ApiService,
    private programService: ProgramsService
  ) { }

  ngOnInit() {
    this.userService.findMyActivePrograms(this.userId)
      .subscribe(user => this.programs = user.data.include.active_programs);
  }

  selectProgram(program): void {
    this.selectedProgram = program;
    if (!program.meta.policy_accepted) {
      this.policy = program.attributes.bases;
      this.displayMode = 'policy';
    } else {
      this.dialogRef.close(program.id);
    }
  }

  isCurrent(program): boolean {
    return program.id == this.apiService.getProgramId();
  }

  acceptBases(): void {
    this.programService.legalBasesAcceptance(this.selectedProgram.id, this.userId)
      .subscribe(() => this.dialogRef.close(this.selectedProgram.id));
  }
}
