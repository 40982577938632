import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  /**
   *Creates an instance of ConfirmDialogComponent.
   * @param {MatDialogRef<ConfirmDialogComponent>} dialogRef
   * @param {*} data
   * @memberof ConfirmDialogComponent
   */
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  /**
   * Close the dialog and send the decision to the opener.
   *
   * @param {*} result
   * @memberof ConfirmDialogComponent
   */
  close(result) {
    this.dialogRef.close(result);
  }
}
