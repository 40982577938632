<div mat-dialog-content class="mb20 content">
  <a [href]="popup.link" target="_blank" *ngIf="popup.link && popup.link != ''">
    <img class="popup-gift" src="{{popup.image}}">
  </a>

  <img class="popup-gift" src="{{popup.image}}" *ngIf="!popup.link || popup.link == ''">
</div>

<div mat-dialog-actions class="actions" align="end">
  <button mat-button color="primary" (click)="close()" tabindex="-1">{{'Accept' | translate}}</button>
</div>