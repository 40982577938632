import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-dialog',
  templateUrl: './privacy-dialog.component.html',
  styleUrls: ['./privacy-dialog.component.scss']
})
export class PrivacyDialogComponent implements OnInit {
  
  cookiesAccepted: boolean = false;
  
  dataForm = this.formBuilder.group({
    program_policy: [false, Validators.required],
    privacy_policy: [false, Validators.required],
  });

  privacyPolicyPopupContent: string = "";
  translateParams = {
    programPolicyLink: "/policies/program-policy",
    privacyPolicyLink: "/policies/privacy-policy"
  };


  constructor(
    public dialogRef: MatDialogRef<PrivacyDialogComponent>,
    public sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.dataForm.get('program_policy').setValue(this.data.data.data.attributes.program_policy === 1 ? true : false);    
    this.dataForm.get('privacy_policy').setValue(this.data.data.data.attributes.program_policy === 1 ? true : false);    
  }

  close(result) {
    this.dialogRef.close(result);
  }
}
