<div class="challenges-list-component">
  <div class="content-box transparent">
    <div class="title" *ngIf="activePhases == 'active'">
      {{ "Your active challenges" | translate }}
    </div>

    <ng-container *ngIf="activeProgram">
      <ng-container *ngFor="let item of phasesList">
        <ng-container *ngIf="isVisible(item)">
          <div
            class="challenge {{ item.attributes.brand }}"
            [ngClass]="{ inactive: isInactive(item) }"
          >
            <div
              class="picture mat-elevation-z1"
              [ngStyle]="{ 'background-image': getPhasePictureBg(item) }"
            ></div>
            <div class="content">
              <div class="content-wrapper mat-elevation-z1">
                <div
                  class="new-label"
                  *ngIf="isNew(item) && isActive(item) && !isCompleted(item)"
                >
                  <span>{{ "New" | translate }}</span>
                </div>
                <div class="completed-label" *ngIf="isCompleted(item)">
                  <span>{{ "Completed" | translate }}</span>
                </div>
                <div>
                  <div class="title">{{ item.attributes.name }}</div>
                  <div
                    class="short-description"
                    [innerHTML]="item.attributes.short_description | safeHtml"
                  ></div>

                  <div class="see-more">
                    <div class="inactive" *ngIf="isInactive(item)">
                      {{ "Inactive" | translate }}
                    </div>
                    <a mat-button (click)="navToChallenge(item)"
                      >+ {{ "Info" | translate }}</a
                    >
                  </div>
                </div>
              </div>
              <div class="dates" *ngIf="isPointsPhase(item)">
                {{ "Sales valid between" | translate }}
                {{ item.attributes.sales_start_at | date : "dd/MM/yyyy" }}
                {{ "and" | translate }}
                {{ item.attributes.sales_end_at | date : "dd/MM/yyyy" }}
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <div *ngIf="showCodeField">
      <app-access-required-dialog></app-access-required-dialog>
    </div>
  </div>
</div>
