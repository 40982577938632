<div class="padding--lvl2">
  <h2>{{data.title|translate}}</h2>

  <form [formGroup]="dataForm" class="mb20">

    <div class="mt20 flex flex-center">
      <mat-slide-toggle color="primary" class="mr10" id="program_policy" formControlName="program_policy" value="1"
        required></mat-slide-toggle>
      <label for="program_policy" [innerHtml]="'PROGRAM_POLICY_MSG' | translate:translateParams"></label>
    </div>
    <mat-error *ngIf="dataForm.get('program_policy').value != true" class="mt10 privacy-policy-error small-text-alert">
      {{'ERROR_REQUIRED_FIELD' | translate}}
    </mat-error>

    <div class="mt20 flex flex-center">
      <mat-slide-toggle color="primary" class="mr10" id="privacy_policy" formControlName="privacy_policy" value="1"
        required></mat-slide-toggle>
      <label for="privacy_policy" [innerHtml]="'PRIVACY_POLICY_MSG' | translate:translateParams"></label>
    </div>
    <mat-error *ngIf="dataForm.get('privacy_policy').value != true" class="mt10 privacy-policy-error small-text-alert">
      {{'ERROR_REQUIRED_FIELD' | translate}}
    </mat-error>

    <div class="mt20">
      <mat-hint>
        {{'Privacy policy has been changed' | translate}}
      </mat-hint>
    </div>

  </form>
  <div mat-dialog-actions align="end">
    <button mat-flat-button class="purple" (click)="close(dataForm.value)"
      [disabled]="!dataForm.controls.program_policy.value || !dataForm.controls.privacy_policy.value">
      <span>{{'Save' | translate}}</span>
    </button>
  </div>
</div>