<h2 mat-dialog-title *ngIf="data.title">{{data.title}}</h2>

<div mat-dialog-content class="mb20" *ngIf="data.content">
  <p [innerHTML]="data.content"></p>
</div>

<div mat-dialog-actions class="flex flex-center">
  <div class="flex-spacer">
    <button mat-button (click)="close(false)"
      tabindex="-1">{{(data.cancelBtn ? data.cancelBtn : 'Cancel') | translate}}</button>
  </div>
  <div>
    <button mat-button (click)="close(true)" color="primary"
      tabindex="-1">{{(data.acceptBtn ? data.acceptBtn : 'Accept') | translate}}</button>
  </div>
</div>