import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../authetication/auth.service';

@Injectable()
export class AuthGuardService  {

  /**
   *Creates an instance of AuthGuardService.
   * @param {AuthService} auth
   * @param {Router} router
   * @memberof AuthGuardService
   */
  constructor(
    public auth: AuthService,
    public router: Router
  ) { }


  /**
   * Indicates if route can be activated
   *
   * @returns {boolean}
   * @memberof AuthGuardService
   */
  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      localStorage.setItem('urlAfterLogin', window.location.href);
      this.router.navigate(['/']);
      return false;
    }

    localStorage.removeItem('urlAfterLogin');
    return true;
  }
}