import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';

export interface User {
  id: number,
  name: string,
  surname: string,
  email: string,
  password: string,
  role_id: number,
  status_id: number,
  points: number
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  public USER_TYPE_OWNER = 1;
  public USER_TYPE_EMPLOYEE = 2;

  /**
   * Api resource
   *
   * @private
   * @type {string}
   * @memberof UsersService
   */
  private resource: string = 'users';

  /**
   * Status active.
   *
   * @type {number}
   * @memberof StatusService
   */
  public POINTS_TYPE_SALES: number = 1;

  /**
   * Status inactive
   *
   * @type {number}
   * @memberof StatusService
   */
  public POINTS_TYPE_REQUESTS: number = 2;

  /**
   * Status pending.
   *
   * @type {number}
   * @memberof StatusService
   */
  public POINTS_TYPE_EXPIRED: number = 3;

  /**
   * Status pending.
   *
   * @type {number}
   * @memberof StatusService
   */
  public POINTS_TYPE_OTHERS: number = 4;

  /**
   *Creates an instance of RolesService.
   * @param {AuthorizationService} http
   * @memberof RolesService
   */
  constructor(private http: ApiService) { }

  /**
   * Find all items.
   *
   * @returns
   * @memberof RolesService
   */
  findAll(filters: Object = {}, sort: string = 'Users.name', order: string = 'asc', pageNumber: number = 0, pageSize: number = 10) {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=roles,status');
  }

  /**
   * Find a single item
   *
   * @param {number} id
   * @returns
   * @memberof RolesService
   */
  findOne(id: number) {
    return this.http.get(`/${this.resource}/${id}?include=locations`);
  }

  /**
   * Get active programs for user.
   */
  findMyActivePrograms(userId: number): Observable<any> {
    return this.http.get(`/${this.resource}/${userId}`, '?include=active_programs');
  }


  /**
   * Stores an item
   *
   * @param {Role} data
   * @returns
   * @memberof RolesService
   */
  store(data: User) {
    if (data.id) {
      return this.http.put(`/${this.resource}/${data.id}`, data);
    } else {
      return this.http.post(`/${this.resource}`, data);
    }
  }

  /**
   * Delete one or multiple items.
   * 
   * @param ids 
   */
  delete(ids) {
    return this.http.delete(`/${this.resource}/${ids.toString()}`);
  }

  /**
   * Get my profile.
   *
   * @param {string} [params={
   *     include: 'locations'
   *   }]
   * @returns {Observable<any>}
   * @memberof UsersService
   */
  getMyProfile(params = {
    include: 'locations'
  }): Observable<any> {
    return this.http.get(`/${this.resource}/${this.http.getUserId()}${this.http.parseParams(params)}`);
  }

  /**
   * Get my grouped sales.
   *
   * @param {*} [params]
   * @returns
   * @memberof UsersService
   */
  getMyGroupedSales(params?) {
    return this.http.get(`/${this.resource}/${this.http.getUserId()}/grouped-sales${this.http.parseParams(params)}`);
  }

  /**
   * Find the user activity.
   *
   * @param {Object} [filters={}]
   * @param {string} [sort='created_at']
   * @param {string} [order='desc']
   * @param {number} [pageNumber=0]
   * @param {number} [pageSize=10]
   * @returns
   * @memberof UsersService
   */
  findMyActivity(filters: Object = {}, sort: string = 'created_at', order: string = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    return this.http.get(`/${this.resource}/${this.http.getUserId()}/activity`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=roles,status');
  }

  /**
   * Find the points history for user.
   *
   * @param {Object} [filters={}]
   * @param {string} [sort='created_at']
   * @param {string} [order='desc']
   * @param {number} [pageNumber=0]
   * @param {number} [pageSize=10]
   * @returns
   * @memberof UsersService
   */
  findMyPointsHistory(filters: Object = {}, sort: string = 'created_at', order: string = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    return this.http.get(`/${this.resource}/${this.http.getUserId()}/points-history`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=roles,status');
  }

  closeAccount(): Observable<any> {
    return this.http.delete(`/${this.resource}/${this.http.getUserId()}/account`);
  }
  
  /**
   * Get points types for users points history
   *
   * @returns
   * @memberof UsersService
   */
  getPointsTypesForFilter(): Array<any> {
    return [
      {
        id: this.POINTS_TYPE_SALES,
        name: "USERS_WALLETS_TYPE_SALES"
      },
      {
        id: this.POINTS_TYPE_REQUESTS,
        name: "USERS_WALLETS_TYPE_REQUESTS"
      },
      {
        id: this.POINTS_TYPE_EXPIRED,
        name: "USERS_WALLETS_TYPE_EXPIRED"
      },
      {
        id: this.POINTS_TYPE_OTHERS,
        name: "USERS_WALLETS_TYPE_OTHERS"
      }
    ];
  }
}
