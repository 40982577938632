import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';


export interface Program {
  id: number,
  name: string,
  status_id: number,
  program_id: number,
  starts_at: string
  ends_at: string,
}

@Injectable({
  providedIn: 'root'
})
export class PhasesService {

  /**
   * API resource.
   *
   * @private
   * @type {string}
   * @memberof PhasesService
   */
  private resource: string = 'phases';

  /**
   * Creates an instance of PhasesService.
   * 
   * @param {ApiService} http
   * @memberof PhasesService
   */
  constructor(
    private http: ApiService
  ) { }

  /**
   * Find all phases.
   *
   * @param {Object} [filters={}]
   * @param {string} [sort='Phases.name']
   * @param {string} [order='asc']
   * @param {number} pageNumber
   * @param {number} [pageSize=10]
   * @returns
   * @memberof PhasesService
   */
  findAll(filters: Object = {}, sort: string = 'Phases.name', order: string = 'asc', pageNumber: number, pageSize: number = 10) {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize) + '&include=status,programs');
  }

  /**
   * Find one phase.
   *
   * @param {number} id
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  findOne(id: number, params?): Observable<any> {
    return this.http.get(`/${this.resource}/${id}`, this.http.parseParams(params));
  }

  /**
   * Find all products assoc to the phase.
   *
   * @param {number} id
   * @returns
   * @memberof PhasesService
   */
  findAllProducts(id: number, filters = {}): Observable<any> {
    return this.http.get(`/${this.resource}/${id}/products`, this.http.buildListQueryString(filters, 'Products.name', 'asc', 0, 1000));
  }

  /**
   * Get phases for selection.
   *
   * @returns
   * @memberof PhasesService
   */
  getForSelection(filters = {}): Observable<any> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, 'Phases.name', 'asc', 0, 5000));
  }

  findAllCatalogGifts(id: number, filters: Object = {}, sort: string = 'Gifts.name', order: string = 'asc', pageNumber: number = 0, pageSize: number = 1000): Observable<any> {
    return this.http.get(`/${this.resource}/${id}/catalog-gifts${this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize)}&include=gifts`);
  }

  /**
   * Store the phase data.
   *
   * @param {Program} data
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  store(data: Program): Observable<any> {
    if (data.id) {
      return this.http.put(`/${this.resource}/${data.id}`, data);
    } else {
      return this.http.post(`/${this.resource}`, data);
    }
  }

  /**
   * Delete the given ids.
   *
   * @param {(Array<number> | number)} ids
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  delete(ids: Array<number> | number): Observable<any> {
    return this.http.delete(`/${this.resource}/${ids.toString()}`)
  }

  /**
   * Get locations ranking for the given phase.
   *
   * @param {number} phaseId
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  getLocationsRanking(phaseId: number): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/locations-ranking`);
  }

  /**
   * Get the social sharing information for the given phase and user.
   *
   * @param {number} phaseId
   * @param {number} userId
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  getSocialSharingInfoForUser(phaseId: number, userId: number): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/users/${userId}`);
  }

  /**
   * Get questions for challenge.
   *
   * @param {number} phaseId
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  getQuestionsForTest(phaseId: number, testId: number): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/${testId}/test-questions`);
  }

  /**
   * Get tests for challenge.
   *
   * @param {number} phaseId
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  getTestsForChallenge(phaseId: number): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/tests-phases`, this.http.buildListQueryString({}, 'name', 'asc', 0, 1000) + '&include=video_url,completed,tests');
  }

  /**
   * Get my answers for the given challenge.
   *
   * @param {number} phaseId
   * @param {number} userId
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  getMyAnswersForChallenge(phaseId: number, userId: number, params?: any): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/users-answers/${userId}${this.http.parseParams(params)}`);
  }

  /**
   * Set the test as completed for the given phase and user.
   *
   * @param {number} phaseId
   * @param {number} userId
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  postTestPhaseCompletion(phaseId: number, testId: number, userId: number): Observable<any> {
    return this.http.post(`/${this.resource}/${phaseId}/test-completed`, {
      user_id: userId,
      test_id: testId
    });
  }

  /**
   * Set the video challenge as completed.
   *
   * @param {number} phaseId
   * @param {number} userId
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  postVideoPhaseCompletion(phaseId: number, userId: number): Observable<any> {
    return this.http.post(`/${this.resource}/${phaseId}/video-completed`, {
      user_id: userId
    });
  }

  postSolaresConfig(phaseId, data): Observable<any> {
    return this.http.post(`/${this.resource}/${phaseId}/solares-config`, data);
  }

  getSolaresConfig(phaseId, locationId): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/solares-config/${locationId}`);
  }

  /**
   * Post user social sharing information.
   *
   * @param {number} phaseId
   * @param {number} userId
   * @param {*} data
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  postSocialSharingInfoForUser(phaseId: number, userId: number, data: any): Observable<any> {
    return this.http.post(`/${this.resource}/${phaseId}/users/${userId}`, data);
  }

  /**
   * Get the categories for products assigned to the given phase.
   *
   * @param {number} phaseId
   * @param {*} [filters={}]
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  getProductsCategories(phaseId: number, filters = {}): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/categories`, this.http.buildListQueryString(filters, 'Categories.name', 'asc', 0, 5000));
  }

  /**
   * Get the record where the user completed the phase.
   *
   * @param {number} phaseId
   * @param {number} userId
   * @param {*} [params]
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  getPhasesUsersCompleted(phaseId: number, userId: number, params?: any): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/users-completed/${userId}${this.http.parseParams(params)}`);
  }

  /**
   * Post the showcase participation.
   *
   * @param {number} phaseId
   * @param {*} data
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  postShowcase(phaseId: number, data: any): Observable<any> {
    return this.http.post(`/${this.resource}/${phaseId}/showcase?include=pictures`, data);
  }

  /**
   * Get the participation data in showcase phases for user.
   *
   * @param {number} phaseId
   * @param {number} userId
   * @param {*} [params]
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  getShowCaseParticipation(phaseId: number, userId: number, params?: any): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/users/${userId}/showcase${this.http.parseParams(params)}`);
  }

  checkAccessCode(data): Observable<any> {
    return this.http.post(`/${this.resource}/check-access-code`, data);
  }

  /**
   * Find one phase.
   *
   * @param {number} userId
   * @returns {Observable<any>}
   * @memberof PhasesService
   */
  getUserStatistics(userId: number, phaseId: number): Observable<any> {
    return this.http.get(`/${this.resource}/${phaseId}/user-statistics/${userId}`);
  }
}
