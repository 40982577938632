import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/http/api.service';
import { PhasesService } from 'src/app/core/http/phases/phases.service';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-access-required-dialog',
  templateUrl: './access-required-dialog.component.html',
  styleUrls: ['./access-required-dialog.component.scss']
})
export class AccessRequiredDialogComponent implements OnInit {

  form: UntypedFormGroup = this.formBuilder.group({
    access_code: ['', Validators.required],
    program_id: ['', Validators.required]
  });

  granted: boolean;

  allowed: boolean = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private phasesService: PhasesService,
    private authService: ApiService,
    private dialog: MatDialog
  ) {
    this.form.get('program_id').setValue(this.authService.getProgramId());
  }

  ngOnInit() {
  }

  store(): void {
    this.phasesService.checkAccessCode(this.form.value)
      .subscribe((response) => {
        if (response.data.length > 0) {
          response.data.forEach((element: any) => {
            if (element.grant === true && element.allowed === true) {
              let storageKey: string = `_ac${element.phase.id}`;
              let hasBeenGrantedBefore = localStorage.getItem(storageKey);
              let data = {
                image: element.phase.picture,
                title: `Has obtenido acceso al reto <b>${element.phase.name}</b>`,
                content: element.phase.access_message
              };
              
              localStorage.setItem(storageKey, '1');

              this.dialog.open(InfoDialogComponent, {
                panelClass: 'access-granted-dialog',
                data: data
              });
            }
          });

        } else {
          this.dialog.open(InfoDialogComponent, {
            data: {
              title: 'Código incorrecto'
            }
          });
        }

        /*if (response.data.grant === true && response.data.allowed === true) {
          let storageKey: string = `_ac${response.data.phase.id}`;
          let hasBeenGrantedBefore = localStorage.getItem(storageKey);
          let data = {
            image: response.data.phase.picture,
            title: `Has obtenido acceso al reto <b>${response.data.phase.name}</b>`,
            content: response.data.phase.access_message
          };

          if (hasBeenGrantedBefore) {
            data = {
              image: null,
              title: `Ya has utilizado este código`,
              content: ''
            };
          }

          this.dialog.open(InfoDialogComponent, {
            data: data
          });

          localStorage.setItem(storageKey, '1');

        } else if (response.data.grant === true && response.data.allowed === false) {
          this.dialog.open(InfoDialogComponent, {
            data: {
              title: 'Lamentamos indicarte que no tienes acceso a este reto.'
            }
          });

        } else {
          this.dialog.open(InfoDialogComponent, {
            data: {
              title: 'Código incorrecto'
            }
          });
        }*/


      });
  }


  /*close(result): void {
    this.dialogRef.close(result);
  }*/
}
