
import { Injectable, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class GaService {

  /**
   * Renderer.
   *
   * @private
   * @type {Renderer2}
   * @memberof GaService
   */
  private renderer: Renderer2

  /**
   * Creates an instance of GaService.
   * 
   * @param {RendererFactory2} rendererFactory
   * @param {*} document
   * @memberof GaService
   */
  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Add tags to document.
   *
   * @memberof GaService
   */
  addTags(): void {
    // Tag manager 
    let script = this.renderer.createElement('script');
    // script.src = `https://www.googletagmanager.com/gtag/js?id=GTM-TSQFJ9L`;
  }
}
