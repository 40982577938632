import { NgModule } from '@angular/core';
import { FontAwesomeModule as NgFontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faStackOverflow,
  faFacebookF,
  faInstagram,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons';

@NgModule({
  declarations: [],
  imports: [
    NgFontAwesomeModule
  ],
  exports: [
    NgFontAwesomeModule
  ]
})
export class FontAwesomeModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faStackOverflow,
      faFacebookF,
      faInstagram,
      faXTwitter
    );
  }
}
