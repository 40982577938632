import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as Fingerprint2 from 'fingerprintjs2';
import { storageConfig } from 'src/app/config/storage.config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   *Creates an instance of AuthService.
   * @memberof AuthService
   */
  constructor(private http: HttpClient) { }


  /**
   * Check if user is authenticated.
   *
   * @returns {boolean}
   * @memberof AuthService
   */
  public isAuthenticated(): boolean {
    // get token and program id from local storage
    let token = localStorage.getItem(storageConfig.authKey);
    let programId = localStorage.getItem('_cpid');
    
    // Check whether the token is expired and return
    // true or false
    if (token && programId) {
      let data = JSON.parse(token);
      if (this.isAccessTokenActive() === false) {
        localStorage.removeItem(storageConfig.authKey);
      } else {
        return true;
      }
    }

    return false;
  }


  /**
   * Check if access token is active.
   *
   * @returns
   * @memberof AuthService
   */
  isAccessTokenActive(): boolean {
    // get token from local storage
    let token = localStorage.getItem(storageConfig.authKey);
    // Check whether the token is expired and return
    // true or false
    if (token) {
      let data = JSON.parse(token);
      if (Math.floor(new Date().getTime() / 1000) > data.expires_at) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }


  /**
   * Get the acces token from local storage.
   *
   * @returns {(string|null)}
   * @memberof AuthService
   */
  getAccessToken(): string | null {
    let auth = localStorage.getItem(storageConfig.authKey);
    if (auth) {
      let data = JSON.parse(auth);
      return data.access_token;
    }

    return null;
  }


  /**
   * Get the refresh token from local storage.
   *
   * @returns {(string|null)}
   * @memberof AuthService
   */
  getRefreshToken(): string | null {
    let auth = localStorage.getItem(storageConfig.authKey);
    if (auth) {
      let data = JSON.parse(auth);
      return data.refresh_token;
    }

    return null;
  }


  /**
   * Get devive fingerprint.
   */
  getFingerprint() {
    return localStorage.getItem('fingerprint');
  }

  /**
   * Set device fingerprint.
   */
  setFingerprint() {
    let fingerprint = this.getFingerprint();
    Fingerprint2.get(function (components) {
      if (!fingerprint) {
        let fingerprint = Fingerprint2.x64hash128(components.map(function (pair) {
          return pair.value
        }).join(), 31);
        localStorage.setItem('fingerprint', fingerprint);
      }
    });
  }

  /**
   * Set the working program id.
   */
  setClientProgramId(programId): void {
    localStorage.setItem('_cpid', programId);
  }


  /**
   * Clear the auth data from storage
   *
   * @memberof AuthService
   */
  clearAuthData() {
    let keys = [
      '_clsession',
      '_cluser',
      '_clfingerprint',
      'popup-seen',
      '_cpid'
    ];
    keys.forEach(element => localStorage.removeItem(element));
  }


  /**
  * Login.
  *
  * @param {*} params
  * @returns {Observable<any>}
  * @memberof LoginService
  */
  login(params): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/login`, params);
  }

  refreshToken() {
    return this.http.post(`${environment.apiUrl}/auth/refresh`, {
      refresh_token: this.getRefreshToken()
    });
  }

  /**
   * Store session in storage
   *
   * @param {*} session
   * @memberof AuthService
   */
  setSession(session): void {
    localStorage.setItem(storageConfig.authKey, JSON.stringify(session.data.attributes));
  }

  /**
   * Logout service.
   *
   * @returns
   * @memberof AuthService
   */
  logout() {
    // Get the access
    let accessToken = this.getAccessToken();
    // Clear the auth data
    this.clearAuthData();

    return this.http.post(`${environment.apiUrl}/auth/logout`, {
      access_token: accessToken
    });
  }

  register(data) {
    return this.http.post(`${environment.apiUrl}/auth/register`, data);
  }

  rememberPassword(data) {
    return this.http.post(`${environment.apiUrl}/auth/remember-password`, data);
  }

  restorePassword(data) {
    return this.http.post(`${environment.apiUrl}/auth/restore-password`, data);
  }

  checkEmailUniqueness(emailAddress) {
    return this.http.get(`${environment.apiUrl}/auth/check-email?email=${emailAddress}`);
  }

  checkEmailLocationRestriction(emailAddress, locationId) {
    return this.http.get(`${environment.apiUrl}/auth/check-location-email?email=${emailAddress}&location_id=${locationId}`);
  }

  checkRecoverHash(hash) {
    return this.http.get(`${environment.apiUrl}/auth/check-recover-hash?hash=${hash}`);
  }

  acceptPolicyUpdate(userId) {
    return this.http.post(`${environment.apiUrl}/auth/accept-policy`, {
      user_id: userId
    });
  }

  acceptEmployeeConsent(data) {
    return this.http.post(`${environment.apiUrl}/auth/employee-consent`, data);
  }

  setCommercialCommunications(data) {
    return this.http.post(`${environment.apiUrl}/auth/commercial-communications`, data);
  }

  checkRegisterHash(hash) {
    return this.http.get(`${environment.apiUrl}/auth/check-register-hash?hash=${hash}`);
  }

  activateAccount(hash) {
    return this.http.post(`${environment.apiUrl}/auth/activate-account`, {
      hash: hash
    });
  }

  getCommercialCommunicationsByHash(hash: string) {
    return this.http.get(`${environment.apiUrl}/auth/${hash}/commercial-communications`);
  }

  unsubscribe(hash: string, data: any) {
    return this.http.post(`${environment.apiUrl}/auth/${hash}/unsubscribe`, data);
  }
}
