import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/authetication/auth.service';
import { SettingsService } from 'src/app/core/http/settings/settings.service';

@Component({
  selector: 'app-commercial-communications',
  templateUrl: './commercial-communications.component.html',
  styleUrls: ['./commercial-communications.component.scss']
})
export class CommercialCommunicationsComponent implements OnInit {

  commercialCommunicationsText = 'Autorizo el envío de comunicaciones informativas vía e-mail relativas al Club y Primaderm';
  commercialCommunicationsTitle = 'Desde Primaderm for Xperts queremos mantenerte informado/a sobre las novedades de nuestros productos y del Club (retos, formación, etc.)'

  form = this.formBuilder.group({
    commercial_communications_text: [this.commercialCommunicationsText, Validators.required],
    commercial_communications: [true],
    user_id: ['', Validators.required]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CommercialCommunicationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService
  ) { 
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.form.get('user_id').setValue(this.data.id);
  }

  accept(): void {
    this.authService.setCommercialCommunications(this.form.getRawValue())
      .subscribe(() => this.dialogRef.close(),
        error => {
          console.log(error);
        })
  }

  decline(): void{
    this.form.get('commercial_communications').setValue(false);
    this.accept();
  }

}
