import { Component, OnInit } from '@angular/core';
import { PostsService } from 'src/app/core/http/posts/posts.service';
import { HelperService } from '../../services/helper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-featured-posts',
  templateUrl: './featured-posts.component.html',
  styleUrls: ['./featured-posts.component.scss']
})
export class FeaturedPostsComponent implements OnInit {

  /**
   * Featured posts.
   *
   * @type {Array<any>}
   * @memberof FeaturedPostsComponent
   */
  featuredPosts: Array<any> = [];

  constructor(
    private postsService: PostsService,
    private helper: HelperService,
    private router: Router
  ) { }

  /**
   * On view init.
   *
   * @memberof FeaturedPostsComponent
   */
  ngOnInit(): void {
    this.fetchPosts();
  }

  /**
   * Fetch posts.
   *
   * @memberof FeaturedPostsComponent
   */
  fetchPosts(): void {
    this.postsService.findAllFeatured()
      .subscribe((items: any) => {
        if(items.meta.count > 0)  {
          this.featuredPosts = items.data;
        }
      })
  }

  /**
   * Get item picture.
   *
   * @param {*} item
   * @returns {string}
   * @memberof FeaturedPostsComponent
   */
  getItemPicture(item: any): string {
    if(item.include.pictures) {
      return `url('${item.include.pictures.attributes.filename}')`;
    }
  }

  /**
   * Navigate to post
   *
   * @param {*} item
   * @memberof FeaturedPostsComponent
   */
  navToPost(item: any): void {
    let route = `/dashboard/blog/${item.id}`;
    this.router.navigate([route]);
  }

}
